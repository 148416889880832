import React, { useState, useEffect } from 'react';

import { Helmet } from 'react-helmet';

import CurrencyFormat from 'react-currency-format';
import { BaseTemplate } from '@templates';
import { Button, HubspotFormModal, PageSection, SEO, PaymongoElement, Toggle } from '@components';

import cardIcon from '@images/icon-credit-card.png';
import eWalletIcon from '@images/icon-ewallet.png';
import otcIcon from '@images/icon-otc.png';
import customBG from '@images/customprice-bg.png';

import './index.scss';

const standardModes = [
  {
    humanReadable: 'Credit or debit cards',
    humanReadableSubtext: '',
    humanReadableShortForm: 'Card',
    mode: 'card',
    icon: cardIcon
  },
  {
    humanReadable: 'Standard foreign fee',
    humanReadableSubtext: 'For cards issued outside the Philippines',
    humanReadableShortForm: 'Foreign fee',
    mode: 'foreign',
    icon: cardIcon
  },
  {
    humanReadable: 'E-wallets',
    humanReadableSubtext: 'GCash, GrabPay',
    humanReadableShortForm: 'E-wallet',
    mode: 'ewallet',
    icon: eWalletIcon
  },
  {
    humanReadable: 'OTC or Coins.ph',
    humanReadableSubtext: '7-Eleven, M Lhuillier, Cebuana',
    humanReadableShortForm: 'OTC+',
    mode: 'otc',
    icon: otcIcon
  }
];

const displayRates = [
  {
    mode: 'card',
    payment: 'credit or debit cards',
    percentage: '3.5%',
    subtext: ' + ₱15'
  },
  {
    mode: 'foreign',
    payment: 'credit or debit cards',
    percentage: '+ 1%',
    subtext: ''
  },
  {
    mode: 'ewallet',
    payment: 'e-wallets',
    percentage: '2.9%',
    subtext: ''
  },
  {
    mode: 'otc',
    payment: 'OTC or Coins.ph',
    percentage: '1.5%',
    subtext: ' (₱10 min)'
  }
];

const errorMessages = {
  minAmount: 'Amount should be at least ₱100.',
  maxAmountEWallet: 'Amount for e-wallets cannot be more than ₱100,000.',
  maxAmountOTC: 'Amount for OTC cannot be more than ₱20,000.'
};

function paymongoPayment(mode, amount, isForeign) {
  const payment = {
    fee: 0,
    netAmount: 0,
    foreignFee: 0
  };

  switch (mode) {
    case 'card':
      payment.fee = amount * 0.035 + 15;
      payment.foreignFee = isForeign ? amount * 0.01 : 0;
      break;
    case 'ewallet':
      payment.fee = amount * 0.029;
      break;
    case 'otc':
      if (amount <= 700) payment.fee = 10;
      else payment.fee = amount * 0.015;
      break;
    default:
      break;
  }

  payment.fee = payment.fee.toFixed(2);
  payment.netAmount = (amount - payment.fee - payment.foreignFee).toFixed(2);
  payment.foreignFee = payment.foreignFee.toFixed(2);

  return payment;
}

const Calculator = () => {
  const [paymentState, setPaymentState] = useState(paymongoPayment('card', 1000, false));
  const [ctState, setCTState] = useState('0');
  const [error, setError] = useState(null);
  const [amount, setAmount] = useState('1000');
  const [paymentMethod, setPaymentMethod] = useState('card');
  const [foreignCard, setForeignCard] = useState(false);

  const validateCalculator = (inputtedAmount, mode) => {
    let errorMessage = null;

    if (mode === 'ewallet' && inputtedAmount > 100000) {
      errorMessage = errorMessages.maxAmountEWallet;
    } else if (mode === 'otc' && inputtedAmount > 20000) {
      errorMessage = errorMessages.maxAmountOTC;
    } else if (inputtedAmount < 100) {
      errorMessage = errorMessages.minAmount;
    }

    return errorMessage;
  };

  useEffect(() => {
    const errorMessage = validateCalculator(amount, paymentMethod);

    setError(errorMessage);

    if (errorMessage !== null) {
      setPaymentState({
        fee: 0,
        netAmount: 0,
        foreignFee: 0
      });
    }
  }, [amount, paymentMethod]);

  // TODO: use paymongo-ui Toggle component once labels are fixed
  const showForeignFee = paymentMethod === 'card' && foreignCard;

  // TODO: Validation of input, shouldn't be lower than 100
  return (
    <div className="sheet calculator">
      <div className="title text-xl">Try our pricing calculator</div>
      <div className="amount-field">
        <p className="text-xs text-sh700 text-left mt-none">Enter amount</p>
        {error !== null && <p className="amount-error">{error}</p>}
        <span className="currency text-lg text-sh600">₱</span>
        <CurrencyFormat
          value={amount}
          decimalScale={2}
          displayType="input"
          thousandSeparator
          className="amount text-lg masket"
          isAllowed={({ value }) => value < 1000000}
          onValueChange={({ value }) => {
            setAmount(value);
            setPaymentState(paymongoPayment(displayRates[ctState].mode, value, foreignCard));
          }}
        />
        <p className="text-xs text-sh700 text-left">Customer's payment method</p>

        <div className="radio-group">
          {standardModes.map((item, i) => {
            return (
              <div key={`pricing-radio-item-${i + 1}`} className={`radio-item ${item.mode}`}>
                <input
                  defaultChecked={item.mode === 'card'}
                  id={`radio-${item.mode}`}
                  ct={i}
                  name="paymentMethod"
                  onChange={e => {
                    setCTState(e.target.getAttribute('ct'));
                    setPaymentState(
                      paymongoPayment(
                        displayRates[e.target.getAttribute('ct')].mode,
                        amount,
                        foreignCard
                      )
                    );
                    setPaymentMethod(displayRates[e.target.getAttribute('ct')].mode);
                  }}
                  type="radio"
                  value={item.mode}
                />
                <label htmlFor={`radio-${item.mode}`}>{item.humanReadableShortForm}</label>
              </div>
            );
          })}
        </div>
      </div>

      <div className="transaction-breakdown">
        <h4 className="text-md text-sh800 mb-none">Transaction breakdown</h4>
        <p className="text-2xs text-sh700 mt-none">
          Customer pays via
          {` `}
          {displayRates[ctState].payment}
        </p>
        <div className="item">
          <div className="detail text-xs text-gr600">PayMongo fee</div>
          <div className="text-md value">
            <span className="text-gr600">
              ₱&nbsp;
              <CurrencyFormat
                value={paymentState.fee}
                decimalScale={2}
                displayType="text"
                thousandSeparator
              />
            </span>
            <p className="subtext text-sh500">
              {displayRates[ctState].percentage}
              {displayRates[ctState].subtext}
            </p>
          </div>
        </div>
        {showForeignFee && (
          <div className="item">
            <div className="detail text-xs text-sh700">Foreign fee</div>
            <div className="text-md value">
              <span className="text-sh700">
                ₱&nbsp;
                <CurrencyFormat
                  value={paymentState.foreignFee}
                  decimalScale={2}
                  displayType="text"
                  thousandSeparator
                />
              </span>
              <p className="subtext text-sh500">{displayRates[1].percentage.substring(2)}</p>
            </div>
          </div>
        )}
        <div className="item">
          <div className="detail text-xs text-pr600">Net amount</div>
          <div className="text-md value text-medium text-pr800">
            ₱&nbsp;
            <CurrencyFormat
              value={paymentState.netAmount}
              decimalScale={2}
              displayType="text"
              thousandSeparator
            />
          </div>
        </div>
        {paymentMethod === 'card' && (
          <div className="foreign-card-toggle">
            <span className="label text-sh700">Is the customer using a foreign card?</span>
            <span>
              <Toggle
                checked={foreignCard}
                onChange={() => {
                  if (!!amount && amount >= 100) {
                    setPaymentState(
                      paymongoPayment(displayRates[ctState].mode, amount, !foreignCard)
                    );
                  }
                  setForeignCard(!foreignCard);
                }}
              />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

// TODO: divide in section when desktop view wireframe is available
const IndexPage = () => {
  const [scheduleModalVisible, setScheduleModalVisible] = useState(false);
  const toggleScheduleModalVisible = () => setScheduleModalVisible(!scheduleModalVisible);

  return (
    <>
      <Helmet>
        <script
          src="https://code.jquery.com/jquery-3.3.1.min.js"
          integrity="sha256-FgpCb/KJQlLNfOu91ta32o/NMZxltwRo8QtmkMRdAu8="
          crossOrigin="anonymous"
        />
      </Helmet>
      <BaseTemplate className="subpage">
        <SEO title="Pricing and fees" />
        <PageSection className="subpage-hero-pricing">
          <div className="columns">
            <div className="column">
              <section className="pricing-grid">
                <div className="pricing-header">
                  <h1 className="text-sh050">Our pricing</h1>
                  <p className="text-sh050 text-md-is-mobile text-sm-is-tablet text-md-is-desktop ">
                    Your growth is our growth. Only pay for successful transactions from your
                    customers.
                  </p>
                </div>
                <div className="sheet">
                  <div className="title text-xl">Simple and straightforward</div>
                  {standardModes.map((item, i) => {
                    return (
                      <div className={`item ${item.mode}`} key={`modes-${item.mode}`}>
                        <div className={`detail text-md ${item.mode}`}>
                          <img alt={item.humanReadable} src={item.icon} />
                          <span>
                            {item.humanReadable}
                            <br />
                            <p className="text-2xs subtext text-sh700">
                              {item.humanReadableSubtext}
                            </p>
                          </span>
                        </div>
                        <div className={`value ${item.mode}`}>
                          <p className="text-xl">{displayRates[i].percentage}</p>
                          <p className="text-xs subtext">{displayRates[i].subtext}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="pricing-subtext">
                  <p className="text-md-is-mobile text-sm-is-tablet text-md-is-desktop">
                    Whether you're an online seller or a big box store, you can use our platform. No
                    setup, one-time or monthly fees!
                  </p>
                  <Button
                    className="button-cta display-block"
                    buttonLabel="Get started for free"
                    destination={process.env.GATSBY_LINK_TO_SIGNUP}
                  />
                </div>
              </section>

              <div className="columns pricing-calc-custom">
                <div className="column">
                  <Calculator />
                </div>
                <div className="column custom-pricing">
                  <h3 className="text-2xl text-gr800">Custom pricing available</h3>
                  <p className="text-lg text-gr800">
                    We adjust our pricing based on the volume of your transactions.
                  </p>
                  <img src={customBG} alt="Custom Pricing" className="custom-pricing-bg" />
                  <PaymongoElement
                    name="Button"
                    props={{
                      className: 'outline block link',
                      onClick: toggleScheduleModalVisible
                    }}
                  >
                    Request for preferential rates
                  </PaymongoElement>
                </div>
              </div>
            </div>
          </div>
        </PageSection>
      </BaseTemplate>
      <HubspotFormModal
        header="Request for preferential rates"
        visible={scheduleModalVisible}
        portalId="7079113"
        formId="f4168cd6-97b5-4f13-a2c5-d8a2366443c3"
        onClose={toggleScheduleModalVisible}
        successMessage="Thank you for filling up the form! We'll get back to you shortly."
      />
    </>
  );
};

export default IndexPage;
